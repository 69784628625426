// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const appSlice = createSlice({
  name : 'apps',
  initialState : {
    items     : [],
    current   : null,
    isLoading : true
  },
  reducers : {
    setCurrentApp(state, { payload }) {
      return ({
        ...state,
        current: payload
      })
    },
    updateApp(state, { payload }) {
      return ({
        ...state,
        items: state.items.map((app) => (app)._id === payload._id ? { ...(app),...payload } : (app))
      });
    },
    deleteApp(state, { payload }) {
      const index = state.items.findIndex((app) => app._id === payload._id);
      if (index === -1) return state;
      return ({
        ...state,
        items: state.items.slice(0, index).concat(state.items.slice(index + 1))
      });
    }
  },
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getApps.fulfilled, (state, { payload }) => ({
        ...state,
        items     : payload.data,
        isLoading : false
      }))
      .addCase(asyncActions.getApps.pending, (state) => ({
        ...state,
        isLoading : true
      }))
      .addCase(asyncActions.getApps.rejected, (state) => ({
        ...state,
        isLoading : false
      }))
  },
});

const appsStore = Object.freeze({
  ...appSlice,
  asyncActions,
});

export default appsStore;
