// CORE
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// MUI
import { Box, Drawer } from "@mui/material";
// ICONS
import { IconLogo } from "../../../../../assets/icons";
// STYLES
import './dashboardSideBar.scss';
// COMPONENTS
import AccordionProjects from "./components/AccordionProjects/AccordionProjects";
import AccordionManage   from "./components/AccordionManage/AccordionManage";
import AccordionAccount  from "./components/AccordionAccount/AccordionAccount";
import AccordionFooter   from "./components/AccordionFooter/AccordionFooter";
import AccordionHSM      from "./components/AccordionHSM/AccordionHSM";


const DashboardSideBar = ({
  className,
}) => {
  const project = useSelector(state => state.project.current);

  return (
    <Box
      className={"side-bar" + (className ? " " + className : "")}
      component="nav"
      aria-label="mailbox folders"
    >
      <Drawer
        className="side-bar__drawer"
        variant="permanent"
        open
      >
        <Box paddingTop="20px">
          <Link
            className="side-bar__logoIcon"
            to={`/projects/${project?._id}`}
            aria-label="go to project"
          >
            <IconLogo/>
          </Link>
          <AccordionProjects/>
          <AccordionManage/>
          <AccordionHSM/>
          <AccordionAccount/>
          <AccordionFooter/>
        </Box>
      </Drawer>
    </Box>
  );
};

export default DashboardSideBar;
