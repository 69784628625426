import { useState } from "react";

import useBillingSettingsByTeam      from "../../../hooks/billingSettings/useBillingSettingsByTeam";
import useCheckPaymentVerifiedByTeam from "../../../hooks/retrieve/useCheckPaymentVerifiedByTeam";
import useNotPaymentVerifiedByTeam   from "../../../hooks/retrieve/useNotPaymentVerifiedByTeam";

const useOpenCompleteBilling = (team) => {
  const notPaymentVerified        = useNotPaymentVerifiedByTeam(team);
  const checkIsNotPaymentVerified = useCheckPaymentVerifiedByTeam(team);
  const billingSettings           = useBillingSettingsByTeam(team);

  const notVerify = (team && !(billingSettings)) || notPaymentVerified;

  const {
    0 : isOpen,
    1 : setIsOpen,
  } = useState(false);

  /**
    * @param {(value: boolean) => any} call
    * @returns {Promise<boolean>}
    */
  const whenIsVerify = async (call) => {
    call(!notVerify);
    setIsOpen(notVerify);
    const currentNotPaymentVerified = await checkIsNotPaymentVerified();
    if (notPaymentVerified === currentNotPaymentVerified) return;
    call(!currentNotPaymentVerified);
    setIsOpen(currentNotPaymentVerified);
    return currentNotPaymentVerified;
  }

  return ({
    isOpen,
    setIsOpen,
    whenIsVerify,
    notVerify,
    checkIsNotPaymentVerified,
  });
};

export default useOpenCompleteBilling;
