// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const securityRuleSlice = createSlice({
    name : 'securityRule',
    initialState : {
        items : [],
        isLoading : false
    },
    reducers : {
        clearRules: (state) => {
            state.items = [];

            return state;
        },
    },
    extraReducers : builder => {
        builder
            .addCase(asyncActions.getRules.fulfilled, (state, { payload }) => {
                state.items = payload.data;
                state.isLoading = false;
            })
            .addCase(asyncActions.getRules.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getRules.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export default ({
    ...securityRuleSlice,
    asyncActions,
});
