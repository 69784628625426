// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const locationSlice = createSlice({
    name : 'location',
    initialState : {
        items : [],
        isLoading : false
    },
    reducers : {
        clearLocations: (state) => {
            state.items = [];

            return state;
        },
    },
    extraReducers : builder => {
        builder
            .addCase(asyncActions.getLocations.fulfilled, (state, { payload }) => {
                state.items = payload.data
                state.isLoading = false
            })
            .addCase(asyncActions.getLocations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(asyncActions.getLocations.rejected, (state) => {
                state.isLoading = false
            })
    },
});

export default ({
    ...locationSlice,
    asyncActions,
});
