import { createAsyncThunk } from "@reduxjs/toolkit";

import { billingSettingsApi } from "../../api";

const asyncTicketActions = Object.freeze({
  deletePaymentMethodById              : createAsyncThunk("payment-method-delete-by-id", billingSettingsApi.deletePaymentMethodById),
  getPaymentMethodsByBillingSettingsId : createAsyncThunk("payment-methods-by-billing-settings-id", billingSettingsApi.getPaymentMethodsByBillingSettingsId),
});

export default asyncTicketActions;
