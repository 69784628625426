// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";

const getApps   = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/apps`);
const getApp    = (appId) => getTrap(`/apps/${appId}`);
const deleteApp = ({ appId }) => axiosInstance.delete(`/apps/${appId}`);
const createApp = (appData) => axiosInstance.post(`/apps`, appData);

const appService = Object.freeze({
  getApps,
  getApp,
  deleteApp,
  createApp
});

export default appService;
