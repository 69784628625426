// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const k8sSlice = createSlice({
    name : 'k8s',
    initialState : {
      items: [],
      versions: [],
      isLoading: false,
    },
    reducers : {
      clearVersions: (state) => {
        state.versions = [];

        return state;
      },
      updateCluster(state, { payload }) {
        state.items = state.items.map(cluster => cluster._id === payload._id ? { ...cluster, ...payload } : cluster);

        return state;
      },
      updateMasterNodeNode(state, { payload }) {
        state.items = state.items.map(cluster => {
          if (payload.cluster._id !== cluster._id) return cluster;

          return {
            ...cluster,
            masterNode: { ...cluster.masterNode, ...payload }
          }
        });

        return state;
      },
      updateWorkerNodeNode(state, { payload }) {
        state.items = state.items.map(cluster => {
          if (payload.pool.cluster._id !== cluster._id) return cluster;

          const pools = cluster.pools.map(pool => ({
            ...pool, nodes: pool.nodes.map(node => {
              if (node._id !== payload._id) return node;

              return { ...node,...payload }
            })
          }))

          return { ...cluster, pools }
        });

        return state;
      },
      deleteCluster(state, { payload }) {
        state.items = state.items.filter(cluster => cluster._id !== payload._id);

        return state;
      },
      deletePool(state, { payload }) {
        state.items = state.items.map(cluster => payload.cluster._id === cluster._id ? ({
          ...cluster,
          pools: cluster.pools.filter(pool => pool._id !== payload._id)
        }) : cluster);

        return state;
      },
      createdPool(state, { payload }) {
        state.items = state.items.map(cluster => payload.cluster._id === cluster._id ? ({
          ...cluster,
          pools: [...cluster.pools, payload]
        }) : cluster);

        return state;
      },
    },
    extraReducers : builder => {
      builder
        .addCase(asyncActions.getVersions.fulfilled, (state, { payload }) => {
          state.versions = payload.data;
        })
        .addCase(asyncActions.getClusters.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(asyncActions.getClusters.fulfilled, (state, { payload }) => {
          state.items = payload.data;
          state.isLoading = false;
        })
        .addCase(asyncActions.getClusters.rejected, (state) => {
          state.isLoading = false;
        })
    },
});

export default ({
    ...k8sSlice,
    asyncActions,
});
