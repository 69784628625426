// CORE
import React from "react";
import { Link } from "react-router-dom";

import "./NoMatch.scss";


const NoMatch = () => (
        <div className="no-match-page">
            <div className="page-not-found-block">
                <div>Page not found :(</div>
                <div>
                    <Link to="/">Go to home page</Link>
                </div>
            </div>
            <div className="error-block prevent-select">404</div>
        </div>
    );

export default NoMatch;
