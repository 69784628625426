// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const imageSlice = createSlice({
    name : 'image',
    initialState : {
        items : [],
        isLoading : false
    },
    reducers : {
        clearImages: (state) => {
            state.items = [];

            return state;
        },
    },
    extraReducers : builder => {
        builder
            .addCase(asyncActions.getImages.fulfilled, (state, { payload }) => {
                state.items = payload.data;
                state.isLoading = false;
            })
            .addCase(asyncActions.getImages.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getImages.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export default ({
    ...imageSlice,
    asyncActions,
});
