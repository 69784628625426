// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import regionService from '../../services/location';


export default ({
    getLocations : createAsyncThunk('get/locations',
        async (params) => regionService.getLocations(params)),

    getLocationsByProviderId : createAsyncThunk('get/locations', regionService.getLocationsByProviderId),
});
