// MUI
import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#f48120",
      dark: "rgb(250, 144, 54)",
      contrastText: "white",
    },
  },
  typography: {
    fontFamily: "Proxima Nova,sans-serif",
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    htmlFontSize: 16,
    h1: {
      fontSize: "2.4rem",
      fontWeight: 300,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h5: {
      fontSize: ".8rem",
      fontWeight: 700,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontSize: "1.5em",
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: "1.25em",
      lineHeight: 1.2,
    },
    body1: {
      fontSize: "1.2em",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "1em",
      lineHeight: 1.5,
    },
  },
  components: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Proxima Nova,sans-serif",
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#151a2d",
          color: "#FFF",
          fontSize: ".8rem",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          color: "#FFF",
        },
      },
    },
  },
});
