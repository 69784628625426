// AXIOS
import { getTrap } from "../../api/axiosInstance";

const getPrices = (({ id, location, image }) => getTrap(`/providers/${id}/locations/${location}/images/${image}/prices`));

const priceService = Object.freeze({
  getPrices
});

export default priceService;
