// CORE
import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
// HOOKS
import useServerMode from "../../hooks/serverMode/useServerMode";
// CONSTANTS
import { maintenance,waitingList,registrationsRoute } from "../../constants/routes/routes";
// COMPONENTS
import PageLoader from "../shared/PageLoader";

const ServerModeProvider = ({ children }) => {
  const { isShowLoader, isShowMaintenance ,isShowWaitingList } = useServerMode();
  const location = useLocation();
  const projectId = localStorage.getItem("project");

  const baseURL = useMemo(
    () => (projectId ? `/projects/${projectId}` : "/"),
    [projectId]
  );

  if (isShowLoader) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <PageLoader />
      </Box>
    );
  }

  if (isShowMaintenance && location.pathname !== maintenance) {
    return <Navigate to={maintenance} />;
  }

  if (!isShowMaintenance && location.pathname === maintenance) {
    return <Navigate to={baseURL} />;
  }

  if(!isShowMaintenance && isShowWaitingList && location.pathname === registrationsRoute ){
    return <Navigate to={waitingList} />;
  }

  if(!isShowMaintenance && !isShowWaitingList && location.pathname === waitingList ){
    return <Navigate to={registrationsRoute} />;
  }

  return children;
};

export default ServerModeProvider;
