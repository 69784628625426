// AXIOS
import { getTrap } from "../../api/axiosInstance";

const getSizes = (({ id, location, group }) => getTrap(`/providers/${id}/locations/${location}/groups/${group}/sizes`));

const sizeService = Object.freeze({
  getSizes,
});

export default sizeService;
