import React, { memo } from "react";

import "./style.scss";

import avatar from "../../../libs/animalGenerator";
import memoizeWeak from '../../../helpers/fp/decorators/memoize/weak';
import empty from "../../../helpers/fp/object/empty";

const getSVG = memoizeWeak((user) => encodeURIComponent(avatar(user.createdAt, { size: 64 })));

const UserAvatar = memo(({
  user,
  className,
  ...props
}) => (
  <span
    {...props}
    className={"user-avatar" + (className ? (" " + className) : "")}
    style={{
      content: `url("data:image/svg+xml, ${(getSVG(user || empty()))}")`,
      ...props.style,
    }}
   />
));

export default UserAvatar;
