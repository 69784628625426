// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";


/** @deprecated */
const getImages = ({ id }) => axiosInstance.get(`/providers/${id}/images`)

/**
  * @param {string} providerId
  */
const getImagesByProviderId = (providerId) => getTrap("/providers/" + providerId + "/images");

const imagesApi = Object.freeze({
  getImages,
  getImagesByProviderId
});

export default imagesApi;
