// CORE
import { Link } from "react-router-dom";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
// ICONS
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
// HELPERS
import { getProjectIcon } from "../../../../../../../helpers/getProjectIcon";


const AccordionProjects = () => {
  const projects = useSelector(state => state.project.items);
  const currentProject = useSelector(state => state.project.current);

  const sortedProjects = useMemo(() => [...projects].sort((a, b) => b.isDefault - a.isDefault), [projects])

  return (
    <Accordion className="side-bar__accordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h5">Projects</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List className="side-bar__project-wrapper">

          {/* PROJECTS */}

          {sortedProjects.map((item) => (
            <ListItem
              className={item._id === currentProject?._id ? 'active' : ''}
              key={item._id}
              disablePadding>
              <Link to={`/projects/${item._id}`}>
                <Box display="flex" alignItems="center">
                  <ListItemIcon>
                    {getProjectIcon(item.icon)}
                  </ListItemIcon>
                  <ListItemButton>
                    <ListItemText primary={item.name}/>
                  </ListItemButton>
                </Box>
              </Link>
            </ListItem>
          ))}

          {/* BTN CREATE PROJECT */}

          <ListItem className="addGroup" key="New ProjectItem" disablePadding>
            <Link to="/projects/new">
              <Box display="flex" alignItems="center">
                <ListItemIcon>
                  <AddIcon/>
                </ListItemIcon>
                <ListItemText primary="New Project"/>
              </Box>
            </Link>
          </ListItem>

        </List>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionProjects;
