import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";

import F from "lodash/fp/F";

import "./BasicLayout.scss";

import {
  CONFIRM_ROUTE,
  createTeamRoute,
  deactivateRoute,
  INVITE_ROUTE,
  loginRoute,
  maintenance,
  NEEDS_ROUTE,
  paymentVerificationRoute,
  profileRoute,
  projectsRoute,
  registrationsRoute,
  waitingList,
} from "../../../constants/routes/routes";
import { IconLogo } from "../../../assets/icons";
import AccountInfo from "../../shared/AccountInfo/AccountInfo";
import Footer from "../../shared/Footer";

import SwitchTeam from "../DashboardLayout/components/SwitchTeam";

// helpers
import Null from "../../../helpers/fp/function/Null";

const LogoBlock = ({ myAcc = false, createTeam = false, defaultPath = "" }) => {
  const profile = useSelector((state) => state.user.userData);
  const { pathname } = useLocation();
  const defaultURL = defaultPath || pathname;
  const linkUrl = profile?._id ? projectsRoute : defaultURL;

  return (
    <Link className={myAcc || createTeam ? "hide-name" : ""} to={linkUrl}>
      <div className="logo-wrapper">
        <IconLogo className="logo" />
      </div>
      {myAcc ? "My Account" : ""}
      {createTeam ? "Create a new team" : ""}
    </Link>
  );
};

const DontHaveAccBlock = () => (
  <>
    Don't have an account?
    <Link to={registrationsRoute}>Sign up</Link>
  </>
);

const AlreadyHaveAccBlock = () => (
  <>
    Already have an account?
    <Link to={loginRoute}>Sign in</Link>
  </>
);

const BasicLayout = () => {
  const profile = useSelector((state) => state.user.userData);
  const { 0: showFooter, 1: setShowFooter } = useState(F);

  const { 0: leftSideComponents, 1: setLiftSideComponents } = useState(Null);

  const { 0: rightSideComponents, 1: setRightSideComponents } = useState(Null);

  const [nextTeam, setNextTeam] = useState(null);

  const { pathname } = useLocation();

  const drawHeaderBlocks = useCallback((path) => {
    switch (path) {
      case INVITE_ROUTE: {
        setLiftSideComponents(<LogoBlock />);
        setRightSideComponents(Null);
        setShowFooter(F);
        break;
      }
      case profileRoute: {
        setLiftSideComponents(<LogoBlock myAcc />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        setShowFooter(F);
        break;
      }
      case deactivateRoute: {
        setLiftSideComponents(<LogoBlock myAcc />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        setShowFooter(F);
        break;
      }
      case paymentVerificationRoute: {
        setLiftSideComponents(<LogoBlock />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        setShowFooter(F);
        break;
      }
      case loginRoute: {
        setLiftSideComponents(<LogoBlock defaultPath="/auth" />);
        setRightSideComponents(<DontHaveAccBlock />);
        setShowFooter(F);
        break;
      }
      case maintenance: {
        setLiftSideComponents(<LogoBlock />);
        setRightSideComponents(Null);
        setShowFooter(F);
        break;
      }
      case waitingList: {
        setLiftSideComponents(<LogoBlock />);
        setRightSideComponents(Null);
        setShowFooter(F);
        break;
      }
      case registrationsRoute: {
        setLiftSideComponents(<LogoBlock defaultPath="/auth" />);
        setRightSideComponents(<AlreadyHaveAccBlock />);
        setShowFooter(F);
        break;
      }
      case createTeamRoute: {
        setLiftSideComponents(<LogoBlock createTeam />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        setShowFooter(F);
        break;
      }
      case NEEDS_ROUTE: {
        setLiftSideComponents(<LogoBlock myAcc />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        setShowFooter(F);
        break;
      }
      case CONFIRM_ROUTE: {
        setLiftSideComponents(<LogoBlock myAcc />);
        setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        setShowFooter(F);
        break;
      }
      default: {
        // Registrations route ant other
        setLiftSideComponents(<LogoBlock />);
        if (profile) {
          setRightSideComponents(<AccountInfo setNextTeam={setNextTeam} />);
        } else {
          setRightSideComponents(<AlreadyHaveAccBlock />);
        }
        setShowFooter(F);
        break;
      }
    }
  }, []);

  useEffect(() => {
    drawHeaderBlocks(pathname);
  }, [drawHeaderBlocks, pathname, profile]);

  return (
    <div className="basic-layout">
      <header>
        <div className="left-side-block d-flex align-center">
          {leftSideComponents}
        </div>
        <div className="right-side-block d-flex align-center">
          {rightSideComponents}
        </div>
      </header>
      <Outlet />
      {showFooter ? <Footer /> : null}
      <SwitchTeam setNextTeam={setNextTeam} nextTeam={nextTeam} />
    </div>
  );
};

export default BasicLayout;
