// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import imageService from '../../services/image';


const imagesAsyncActions = Object.freeze({
  getImagesByProviderId : createAsyncThunk('get/imagesByProviderId', imageService.getImagesByProviderId),
});

export default imagesAsyncActions;
