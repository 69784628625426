// CORE
import * as React from 'react';
import { useEffect, useState } from "react";

import F from 'lodash/fp/F';
import T from 'lodash/fp/T';

// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// STYLES
import './AutocompleteSearchResource.scss'


const AutocompleteSearchResource = ({ fullWidth = false }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (open) {
      setLoading(T);
      setTimeout(setLoading,1000, F);
    } else {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="search-resource"
      fullWidth={fullWidth}
      noOptionsText={`No results found containing ${searchValue ? '"' + searchValue + '"' : ''}`}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      className='search-resource'
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
            {...params}
            placeholder="Search by resource name or public IP (Ctrl+B)"
            onChange={e => setSearchValue(e.target.value)}
            InputProps={{
                ...params.InputProps,
                endAdornment : null,
                startAdornment : loading
                  ? <CircularProgress color="inherit" size={14}/>
                  : <ZoomInIcon className="zoom-icon"/>,
            }}
        />
      )}
    />
  );
};

export default AutocompleteSearchResource;
