// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";

const getVirtualMachines = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/virtual-machines`);
const getVirtualMachine = (vmId) => getTrap(`/virtual-machines/${vmId}`);
const stopVirtualMachine = (vmId) => axiosInstance.put(`/virtual-machines/${vmId}/stop`);
const startVirtualMachine = (vmId) => axiosInstance.put(`/virtual-machines/${vmId}/start`);
const deleteVirtualMachine = ({ vmId }) => axiosInstance.delete(`/virtual-machines/${vmId}`);
const createVirtualMachines = (data) => axiosInstance.post(`/virtual-machines`, data);
const getRules = (vmId) => getTrap(`/virtual-machines/${vmId}/security-rules `);

export default {
  getVirtualMachines,
  getVirtualMachine,
  createVirtualMachines,
  deleteVirtualMachine,
  stopVirtualMachine,
  startVirtualMachine,
  getRules
}
