// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import imageService from '../../services/image';


export default ({
    getImages : createAsyncThunk('get/images',
        async (params) => imageService.getImages(params)),
});
