// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import priceService from '../../services/price';

const pricesAsyncActions = Object.freeze({
  getPrices : createAsyncThunk('_get/prices', priceService.getPrices),
});

export default pricesAsyncActions;
