export const downloadFile = ({ value, name }) => {
  const element = document.createElement('a');
  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(value);

  element.setAttribute("href", dataStr);
  element.setAttribute('download', name);

  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element)
}

export const downloadFileByURL = ({ url, name }) => {
  const element = document.createElement('a');

  element.setAttribute("href", url);
  element.setAttribute('download', name);
  element.setAttribute('target', "_blank");

  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element)
}
