export const notificationTypes = {
  invite: 0,
  invoicePaid: 1,
  invoiceFailed: 2,
};

export const notificationStatuses = {
  unread: 0,
  read: 1,
  accepted: 2,
  rejected: 3,
};
