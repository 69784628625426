// CORE
import { createSlice } from '@reduxjs/toolkit';

import partition from 'lodash/fp/partition';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from '../../helpers/fp/array/empty';
import eq    from '../../helpers/fp/array/internal/eq';
import DataCache from '../../helpers/DataCache';

const bySize = ({ provider, location, group }) => (size) => (
  (size.provider === provider) &&
  (size.location === location) &&
  (size.group    === group)
);

const {
  save,
  load
} = DataCache("sizes");

const sizesSlice = createSlice({
  name : 'sizes',
  initialState : load() || empty(),
  reducers : {
    clearSizes: empty,
  },
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getSizes.fulfilled, (state, { payload, meta }) => {
        const { id, location, group } = meta.arg;
        if (!payload.data.length) return state;
        if (!state.length)        return save(payload.data);

        const [byArgs, byStore] = partition(bySize({ provider: id, location, group }), state);
        if (!byArgs.length)  return save(state.concat(payload.data));
        if (!byStore.length) return save(payload.data);

        if (eq(byArgs, payload.data)) return state;
        return save(byStore.concat(payload.data));
      });
  },
});

const sizesStore = Object.freeze({
  ...sizesSlice,
  asyncActions,
});

export default sizesStore;
