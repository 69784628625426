// AXIOS
import { getTrap } from "../../api/axiosInstance";

/**
  * @typedef {import("../../types/Provider").default} Provider
  */

const getProviders = ((params) => getTrap(`/providers?type=${params.type}`));

/**
  * @param {Provider["type"]} type
  * @returns {Promise<import("axios").AxiosResponse<(Provider)[]>>}
  */
const getProvidersByType = (type) => getTrap(`/providers?type=${type}`);

const providerService = Object.freeze({
  getProviders,
  getProvidersByType,
});

export default providerService;
