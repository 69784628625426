// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import k8sService from '../../services/k8s';


export default ({
  getVersions: createAsyncThunk('get/versions', k8sService.getVersions),
  getClusters: createAsyncThunk('get/clusters', k8sService.getClusters),
  getCluster: createAsyncThunk('get/cluster', k8sService.getCluster)
});
