import axiosInstance from "../axiosInstance";

/**
 * @param {object} params
 * @param {`${string}@${string}.${string}`} params.email
 * @param {string} params.password
 * @param {string} params.inviteId
 */
const acceptInvite = ({
                        inviteId,
                        password,
                        email,
                      }) => axiosInstance.post(`/invite/${inviteId}`, {
  password,
  email,
});

const acceptInviteByNotification = ({ id }) => axiosInstance.put(`/invite/${id}/accept`);

const inviteApi = Object.freeze({
  acceptInvite,
  acceptInviteByNotification
});

export default inviteApi;
