export const backgroundColors = [
    '#fcf7d1',
    '#ece2e1',
    '#e4e3cd',
    '#c4ddd6',
    '#b5f4bc',
];
export const avatarColors = [
    '#d7b89c',
    '#b18272',
    '#ec8a90',
    '#a1Ac88',
    '#99c9bd',
    '#50c8c6',
];
