// AXIOS
import { getTrap } from "../../api/axiosInstance";


/**
  * @deprecated
  */
const getLocations = ({ id }) => getTrap(`/providers/${id}/locations`)

/** @param {string} id */
const getLocationsByProviderId = (id) => getTrap(`/providers/${id}/locations`);

const regionService = Object.freeze({
  getLocations,
  getLocationsByProviderId,
});

export default regionService;
