// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import partition from "lodash/fp/partition";
import asyncActions from './asyncActions';

// helpers
import empty from "../../helpers/fp/array/empty";
import eq    from '../../helpers/fp/array/internal/eq';
import DataCache from '../../helpers/DataCache';


const byPrice = ({ providerId, location, image }) => (price) => (
  (price.provider     === providerId) &&
  (price.locationName === location) &&
  (price.imageName    === image)
);

const {
  save,
  load
} = DataCache("prices");

const pricesSlice = createSlice({
  name : 'prices',
  initialState : load() || empty(),
  reducers : {
    clearPrices: empty,
  },
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getPrices.fulfilled, (state, { payload, meta }) => {
        const { id, location, image } = meta.arg
        if (!payload.data.length) return state;
        if (!state.length)        return save(payload.data);

        const [byArgs, byStore] = partition(byPrice({ providerId: id, location, image }), state);

        if (!byArgs.length)  return save(state.concat(payload.data));
        if (!byStore.length) return save(payload.data);

        if (eq(byArgs, payload.data)) return state;
        return save(byStore.concat(payload.data));
      });
  },
});

const pricesStore = Object.freeze({
  ...pricesSlice,
  asyncActions,
});

export default pricesStore;
