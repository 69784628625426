// CORE
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ENV from "../../constants/ENV";

// STORE
import virtualMachineStore from "../../store/virtualMachine";
import k8sStore from "../../store/k8s";
import databaseStore from "../../store/databases";
import appStore from "../../store/apps";
import accessToken from "../../helpers/accessToken";
import billingSettingsStore from "../../store/billingSetting";
import teamStore from "../../store/team";
import costStore from "../../store/costs";
import serverModeStore from "../../store/serverMode";
import { setUserData } from "../../store/user/actions";
import { userStatuses } from "../../constants/user";
import notificationStore from "../../store/notification";

const SOCKET_URL = ENV.REACT_APP_SOCKET_URL;

const useSocket = () => {
  const profile = useSelector((state) => state.user.userData);
  const [socket, setSocket] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateBillingSettings = (billingSettings) =>
    dispatch(billingSettingsStore.actions.set(billingSettings));

  const handleUpdateCluster = (cluster) =>
    dispatch(k8sStore.actions.updateCluster(cluster));

  const handleDeleteCluster = (cluster) =>
    dispatch(k8sStore.actions.deleteCluster(cluster));

  const handleDeletePool = (pool) =>
    dispatch(k8sStore.actions.deletePool(pool));

  const handleCreatePool = (pool) =>
    dispatch(k8sStore.actions.createdPool(pool));

  const handleUpdateMasterNode = (masterNode) =>
    dispatch(k8sStore.actions.updateMasterNodeNode(masterNode));

  const handleUpdateWorkerNode = (workerNode) =>
    dispatch(k8sStore.actions.updateWorkerNodeNode(workerNode));

  const handleUpdateVM = (vm) =>
    dispatch(virtualMachineStore.actions.updateVirtualMachine(vm));

  const handleDeleteVM = (vm) =>
    dispatch(virtualMachineStore.actions.deleteVirtualMachine(vm));

  const handleFailVM = (vm) =>
    dispatch(virtualMachineStore.actions.updateVirtualMachine(vm));

  const handleUpdateDB = (db) =>
    dispatch(databaseStore.actions.updateDatabase(db));

  const handleDeleteDB = (db) =>
    dispatch(databaseStore.actions.deleteDatabase(db));

  const handleFailDB = (db) =>
    dispatch(databaseStore.actions.updateDatabase(db));

  const handleUpdateApp = (app) => dispatch(appStore.actions.updateApp(app));

  const handleDeleteApp = (app) => dispatch(appStore.actions.deleteApp(app));

  const handleFailApp = (app) => dispatch(appStore.actions.updateApp(app));

  const handleSuspendUser = (user) =>
    dispatch(
      teamStore.actions.updateStatusTeamMember({
        ...user,
        status: userStatuses.suspended,
      })
    );

  const handleActivateUser = (user) =>
    dispatch(
      teamStore.actions.updateStatusTeamMember({
        ...user,
        status: userStatuses.active,
      })
    );

  const handleDeactivateUser = (user) =>
    dispatch(
      teamStore.actions.updateStatusTeamMember({
        ...user,
        status: userStatuses.deactivated,
      })
    );

  const handleUpdateActualCost = (actualCost) =>
    dispatch(costStore.actions.updateTeamCosts(actualCost));

  const handleUpdateServerMode = (mode) =>
    dispatch(serverModeStore.actions.updateSeverMode(mode));

  const onVerify = (user) => {
    dispatch(setUserData(user));
  };

  const handleCreateNotification = () =>
    dispatch(
      notificationStore.asyncActions.getNotifications({
        page: 1,
        limit: 10,
      })
    );

  const token = accessToken.value;
  const profileId = profile?._id;

  useEffect(() => {
    if (!profileId || !token) return;

    setSocket(
      io(SOCKET_URL, {
        autoConnect: false,
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  }, [profileId, token]);

  useEffect(() => {
    if (socket) socket.connect();

    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.on("updatedBillingSettings", handleUpdateBillingSettings);
      socket.on("updatedVirtualMachine", handleUpdateVM);
      socket.on("deletedVirtualMachine", handleDeleteVM);
      socket.on("failedVirtualMachine", handleFailVM);
      socket.on("updatedDatabase", handleUpdateDB);
      socket.on("deletedDatabase", handleDeleteDB);
      socket.on("failedDB", handleFailDB);
      socket.on("updatedApp", handleUpdateApp);
      socket.on("deletedApp", handleDeleteApp);
      socket.on("failedApp", handleFailApp);
      socket.on("updatedCluster", handleUpdateCluster);
      socket.on("deletedCluster", handleDeleteCluster);
      socket.on("deletedPool", handleDeletePool);
      socket.on("updatedMasterNode", handleUpdateMasterNode);
      socket.on("updatedWorkerNode", handleUpdateWorkerNode);
      socket.on("createdPool", handleCreatePool);
      socket.on("suspendUser", handleSuspendUser);
      socket.on("activateUser", handleActivateUser);
      socket.on("deactivateUser", handleDeactivateUser);
      socket.on("updatedActualCost", handleUpdateActualCost);
      socket.on("userVerify", onVerify);
      socket.on("updatedServerMode", handleUpdateServerMode);
      socket.on("createNotification", handleCreateNotification);
    }

    return () => {
      if (socket) {
        socket.off("updatedBillingSettings", handleUpdateBillingSettings);
        socket.off("updatedVirtualMachine", handleUpdateVM);
        socket.off("deletedVirtualMachine", handleDeleteVM);
        socket.off("failedVirtualMachine", handleFailVM);
        socket.off("updatedDatabase", handleUpdateDB);
        socket.off("deletedDatabase", handleDeleteDB);
        socket.off("failedDB", handleFailDB);
        socket.off("updatedApp", handleUpdateApp);
        socket.off("deletedApp", handleDeleteApp);
        socket.off("failedApp", handleFailApp);
        socket.off("updatedCluster", handleUpdateCluster);
        socket.off("deletedCluster", handleDeleteCluster);
        socket.off("deletedPool", handleDeletePool);
        socket.off("updatedMasterNode", handleUpdateMasterNode);
        socket.off("updatedWorkerNode", handleUpdateWorkerNode);
        socket.off("createdPool", handleCreatePool);
        socket.off("suspendUser", handleSuspendUser);
        socket.off("activateUser", handleActivateUser);
        socket.off("deactivateUser", handleDeactivateUser);
        socket.off("updatedActualCost", handleUpdateActualCost);
        socket.off("userVerify", onVerify);
        socket.off("updatedServerMode", handleUpdateServerMode);
        socket.off("createNotification", handleCreateNotification);
      }
    };
  }, [socket]);
};

export default useSocket;
