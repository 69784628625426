import React from "react";

import "./style.scss";
import { Box, IconButton, Modal, Typography } from "@mui/material";


const ModalWindow = ({
  open,
  setOpen,
  children,
  title,
}) => (
  <Modal
    open={open}
    onClose={() => setOpen(false)}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box
      padding="1.25em 1.5em"
      backgroundColor="white"
      border="1px solid rgba(210, 210, 210, 0.9)"
      borderRadius=".25em"
      gap=".5em"
      display="flex"
      flexDirection="column"
      className="modal-window-base"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          sx={{
            display: "inline",
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            width: "1.5em",
            height: "1.5em",
          }}
        >
          &times;
        </IconButton>
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  </Modal>
);

export default ModalWindow;
