// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import appService from '../../services/app';


export default ({
    getApps : createAsyncThunk('get/apps', async (params) => appService.getApps(params)),
    createApp : createAsyncThunk('post/apps', async (params) => appService.createApp(params)),
    deleteApp : createAsyncThunk('delete/apps', async (params) => appService.deleteApp(params)),
});
