// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import virtualMachineService from '../../services/virtualMachine';


export default ({
  createVirtualMachines : createAsyncThunk('post/virtualMachines',    virtualMachineService.createVirtualMachines),
  getVirtualMachines    : createAsyncThunk('get/virtualMachines',     virtualMachineService.getVirtualMachines),
  getVirtualMachine     : createAsyncThunk('get/getVirtualMachine',   virtualMachineService.getVirtualMachine),
  stopVirtualMachine    : createAsyncThunk('get/stopVirtualMachine',  virtualMachineService.stopVirtualMachine),
  startVirtualMachine   : createAsyncThunk('get/startVirtualMachine', virtualMachineService.startVirtualMachine),
  deleteVirtualMachine  : createAsyncThunk('delete/virtualMachines',  virtualMachineService.deleteVirtualMachine),
});
