// CORE
import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import "./AccountInfo.scss";

// MUI
import {
  Avatar,
  Badge,
  Box,
  List,
  ListItem,
  Popover,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
// IMAGES
import classNames from "classnames";
import F from "lodash/fp/F";
import referralAvatar from "../../../assets/images/referral.png";
// CONSTANTS
import {
  createTeamRoute,
  profileRoute,
  REFERRAL_ROUTE,
} from "../../../constants/routes/routes";
// STORE
import userActions from "../../../store/user/actions";
// HELPERS
import Null from "../../../helpers/fp/function/Null";
import { getUserAbbr } from "../../../helpers/team";

// COMPONENTS
import TeamAvatar from "../../common/TeamAvatar";
import UserAvatar from "../UserAvatar";

import isEmpty from "../../../helpers/fp/object/isEmpty";
import accessToken from "../../../helpers/accessToken";
import getCost from "../../../helpers/getCost";

import useFetchTeams from "../../../hooks/teams/useFetchTeams";
import useAuthUser from "../../../hooks/user/useAuthUser";
import isAnySuspendedByOwner from "../../../helpers/fp/models/team/isAnySuspendedByOwner";
import useCurrentTeam from "../../../hooks/teams/useCurrentTeam";

const AccountInfo = ({ setNextTeam }) => {
  const { 0: anchorEl, 1: setAnchorEl } = useState(Null);

  const team = useCurrentTeam();
  const teams = useSelector((state) => state.team.items);
  const fetchTeams = useFetchTeams();
  const user = useAuthUser();
  const navigate = useNavigate();

  const costs = useSelector((state) => state.cost.data);

  const { 0: showTeams, 1: setShowTeams } = useState(F);

  const showSwitchTeam = teams.length > 1;
  const handleChangeShowTeams = useCallback(() => {
    fetchTeams();
    setShowTeams((show) => !show);
  }, [setShowTeams]);

  const handleClose = useCallback(() => {
    setAnchorEl(Null);
    setShowTeams(F);
  }, []);

  const handleSwitchTeam = useCallback(
    async (e) => {
      const teamId = e.currentTarget.getAttribute("data-team-id");
      if (teamId === team._id) return null;
      const nextTeam = teams.find((data) => data._id === teamId);
      if (!nextTeam) return null;
      setNextTeam(nextTeam);
      handleClose();
    },
    [setNextTeam, teams, team]
  );

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const name = team?.name || user?.name;

  if (
    !accessToken.value ||
    ((!team || isEmpty(team)) && (!user || isEmpty(user)))
  )
    return (
      <Link to="/auth" className="link">
        Sign in
      </Link>
    );

  const { emailVerified } = user;

  const showReferral = Boolean(0 && emailVerified && !showTeams);

  const isDisabledCreate = !emailVerified || isAnySuspendedByOwner(user)(teams);

  return (
    <Box
      className="dashboard-header__account-wrapper"
      sx={{
        flexGrow: 1,
      }}
    >
      <Box minWidth="140px">
        <Typography
          className="dashboard-header__account-name"
          variant="subtitle1"
        >
          {name}
        </Typography>
        <Typography
          className="dashboard-header__balance"
          color="black"
          variant="body1"
        >
          Estimated costs € {getCost(costs.total)}
        </Typography>
      </Box>
      <Box onClick={handleClick} className="dashboard-header__avatar-wrap">
        <Badge
          sx={{ marginLeft: "16px" }}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <UserAvatar style={{ width: "22px", height: "22px" }} user={user} />
          }
        >
          <TeamAvatar teamName={getUserAbbr(user)} title={user?.name} />
        </Badge>
        <ExpandMoreIcon className={anchorEl ? "up" : ""} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="profile-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          className={classNames("profile-container", {
            "profile-container__teams": showTeams,
          })}
        >
          <Box className="profile">
            <UserAvatar user={user} />
            <Box className="profile__info-wrapper">
              <Typography variant="body2">{user.name}</Typography>
              <Typography variant="body2">Owner</Typography>
            </Box>
          </Box>
          {!showTeams && (
            <List className="menu-customer">
              <Link
                to={emailVerified ? profileRoute : "#"}
                title={
                  emailVerified ? "" : "Please verify your email address first"
                }
              >
                <ListItem
                  style={{
                    opacity: emailVerified ? 1 : 0.5,
                    cursor: emailVerified ? "pointer" : "not-allowed",
                  }}
                >
                  My Account
                </ListItem>
              </Link>
              {showSwitchTeam && (
                <ListItem
                  onClick={handleChangeShowTeams}
                  className="menu-customer__switch-team"
                >
                  <div>Switch teams</div>
                  <div>
                    <ArrowForwardIosIcon fontSize="small" />
                  </div>
                </ListItem>
              )}
              <ListItem
                disabled={isDisabledCreate}
                onClick={() =>
                  isDisabledCreate ||
                  (setAnchorEl(Null), navigate(createTeamRoute))
                }
                sx={{
                  cursor: isDisabledCreate ? "not-allowed" : "pointer",
                }}
              >
                <Link
                  className="create-team-link"
                  to={isDisabledCreate ? createTeamRoute : "#"}
                  title={
                    emailVerified
                      ? ""
                      : "Please verify your email address first"
                  }
                  aria-disabled={isDisabledCreate}
                >
                  Create a Team
                </Link>
              </ListItem>
              <ListItem onClick={() => userActions.logout()}>Sign Out</ListItem>
            </List>
          )}
          {showTeams && (
            <List className="menu-customer menu-customer__teams">
              <ListItem
                onClick={handleChangeShowTeams}
                className="menu-customer__back-menu"
              >
                <div>
                  <ArrowBackIosIcon fontSize="small" />
                </div>
                <div>Back</div>
              </ListItem>
              {teams.map((item) => (
                <ListItem
                  onClick={handleSwitchTeam}
                  key={item._id}
                  data-team-id={item._id}
                  className={classNames("menu-customer__team", {
                    "menu-customer__current-team": item._id === team._id,
                  })}
                >
                  <div>{item.name}</div>
                  {item._id === team._id && (
                    <div>
                      <DoneIcon />
                    </div>
                  )}
                </ListItem>
              ))}
            </List>
          )}
          {showReferral && (
            <Box className="referral">
              <Avatar
                alt="Chest of gold"
                variant="square"
                src={referralAvatar}
              />
              <Box className="referral__info-wrapper">
                <Typography variant="body2">
                  Set up a referral link and earn free credits
                </Typography>
                <Link to={REFERRAL_ROUTE}>learn more</Link>
              </Box>
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default AccountInfo;
