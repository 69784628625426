// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import priceService from '../../services/price';


export default ({
    getPrices : createAsyncThunk('get/prices',
        async (params) => priceService.getPrices(params)),
});
