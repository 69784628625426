// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import databaseService from '../../services/database';


export default ({
    getDatabases   : createAsyncThunk('get/databases', async (params) => databaseService.getDatabases(params)),
    createDatabase : createAsyncThunk('post/databases', async (params) => databaseService.createDatabase(params)),
    deleteDatabase : createAsyncThunk('delete/databases', async (params) => databaseService.deleteDatabase(params)),
});
