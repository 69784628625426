// CORE
import React from "react";
import { Link } from "react-router-dom";
// MUI
import { Box } from "@mui/material";
// ICONS
import { IconInfo } from "../../../../../assets/icons";

import teamGetOwner from "../../../../../helpers/fp/models/team/getOwner";
import isSuspendedTeam from "../../../../../helpers/fp/models/team/isSuspended";
import isSuspendedUser from "../../../../../helpers/fp/models/user/isSuspendedUser";

const profileIsOwner = (profile, owner) => profile?._id === owner?._id;

const getMessage = (team, profile) => {
  if (!team) return "";
  const owner = teamGetOwner(team);
  if (isSuspendedUser(owner)) {
    if (profileIsOwner(profile, owner)) return "Your account is suspended";
    return "These team is suspended";
  }
  if (isSuspendedTeam(team)) return "This team is suspended";
  return "";
}

const SuspendTip = ({
  profile,
  team,
}) => {
  const message = getMessage(team, profile);
  if (!message) return null;

  return (
    <Box className="account-tip">
      <IconInfo />
        {message},
        <span style={{ paddingLeft: "3px" }}>check the</span>
        &nbsp;
      <Link className="inherit" to="/billing">
        payment
      </Link>
      &nbsp;
      <span>
        of your subscription.
      </span>
    </Box>
  );
};

export default SuspendTip;
