/* eslint-disable no-debugger */
// CORE
import React, { useEffect } from "react";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

// MUI
import Box from "@mui/material/Box";

// CONSTANTS
import complement from "lodash/fp/complement";

import {
  CONFIRM_ROUTE,
  INVITE_PATH,
  INVITE_ROUTE,
  KUBERNETES_NEW_ROUTE,
  KUBERNETES_ROUTE,
  NEEDS_ROUTE,
  PAYMENT_METHOD_PATH,
  REACTIVATE_ROUTE,
  REFERRAL_ROUTE,
  SECRETS_ROUTE,
  TICKETS_ROUTE,
  appNewRoute,
  appRoute,
  billingRoute,
  confirmEmailRoute,
  createTeamRoute,
  databaseNewRoute,
  databaseRoute,
  deactivateRoute,
  forgotPasswordRoute,
  isNeedAuth,
  loginRoute,
  maintenance,
  paymentVerificationRoute,
  profileRoute,
  projectItemRoute,
  projectsNewRoute,
  projectsRoute,
  registrationsRoute,
  settingsRoute,
  virtualMachineNewRoute,
  virtualMachineRoute,
  waitingList,
  AUTH_ROUTE,
} from "./constants/routes/routes";

// COMPONENTS
import BasicLayout from "./components/layouts/BasicLayout/BasicLayout";
import DashboardLayout from "./components/layouts/DashboardLayout/DashboardLayout";
import ErrorBoundary from "./components/shared/ErrorBoundary";
import NoMatch from "./pages/NoMatch/NoMatch";
import PageLoader from "./components/shared/PageLoader";

// hooks
import useAuthUser from "./hooks/user/useAuthUser";
import useCurrentTeam from "./hooks/teams/useCurrentTeam";
import useNeNeedNeeds from "./hooks/needs/useNeNeedNeeds";
import useCheckPaymentVerifiedByTeam from "./hooks/retrieve/useCheckPaymentVerifiedByTeam";
import useSkipPaymentMethod from "./hooks/retrieve/useSkip";
import useSkipNeeds from "./hooks/needs/useSkipNeeds";

//     helpers
import neAuth from "./helpers/neAuth";
import showError from "./helpers/showError";
import getPathName from "./helpers/fp/window/getPathName";
import isEmpty from "./helpers/fp/object/isEmpty";
import isRootPath from "./helpers/fp/window/isRootPath";
import once from "./helpers/fp/decorators/once";
import isCurrentPathName from "./helpers/fp/window/isCurrentPathName";
import someTeamOwnerByUser from "./helpers/fp/models/team/someTeamOwnerByUser";

import { userStatuses } from "./constants/user";
import usePaymentMethodsByBillingSettingsId from "./hooks/paymentMethods/usePaymentMethodsByBillingSettingsId";
import useBillingSettingsByTeam from "./hooks/billingSettings/useBillingSettingsByTeam";
import isAllowPaymentMethod from "./helpers/fp/models/paymenMethod/isAllow";

// LAZY PAGES
const Login = React.lazy(() => import("./pages/Auth/Login/LoginContainer"));
const AUTH = React.lazy(() => import("./pages/Auth/Auth"));
const Registrations = React.lazy(() =>
  import("./pages/Auth/Registrations/RegistrationsContainer")
);
const Confirm = React.lazy(() => import("./pages/Auth/Confirm"));
const ConfirmEmail = React.lazy(() =>
  import("./pages/Auth/ConfirmEmail/ConfirmEmailContainer")
);
const ForgotPassword = React.lazy(() =>
  import("./pages/Auth/ForgotPassword/ForgotPasswordContainer")
);
const Needs = React.lazy(() => import("./pages/Auth/Needs"));
const PaymentVerification = React.lazy(() =>
  import("./pages/Auth/PaymentVerification/PaymentVerificationContainer")
);
const ResetPassword = React.lazy(() =>
  import("./pages/Auth/ResetPassword/ResetPasswordContainer")
);

const AppItem = React.lazy(() => import("./pages/AppItem/AppItemContainer"));
const AppNew = React.lazy(() => import("./pages/AppNew/AppNewContainer"));
const AppsList = React.lazy(() => import("./pages/AppsList/AppsListContainer"));
const Billing = React.lazy(() => import("./pages/Billing/BillingContainer"));

const CreateTeam = React.lazy(() => import("./pages/CreateTeam/Create")); // STEP 1
const CreateTeamEdit = React.lazy(() => import("./pages/CreateTeam/Edit")); // STEP 1
const CreateTeamAddPaymentMethod = React.lazy(() =>
  import("./pages/CreateTeam/AddPaymentMethod")
); // STEP 2
const CreateTeamInvite = React.lazy(() =>
  import("./pages/CreateTeam/InviteMembers")
); // STEP 3

const InvitePage = React.lazy(() => import("./pages/Invite"));
const CreateProject = React.lazy(() =>
  import("./pages/CreateProject/CreateProjectContainer")
);
const DatabaseItem = React.lazy(() =>
  import("./pages/DatabaseItem/DatabaseItemContainer")
);
const DatabaseNew = React.lazy(() =>
  import("./pages/DatabaseNew/DatabaseNewContainer")
);
const DatabasesList = React.lazy(() =>
  import("./pages/DatabasesList/DatabasesListContainer")
);
const Deactivate = React.lazy(() =>
  import("./pages/Deactivate/DeactivateContainer")
);
const Profile = React.lazy(() => import("./pages/Profile/ProfileContainer"));
const ProjectItem = React.lazy(() =>
  import("./pages/ProjectItem/ProjectItemContainer")
);
const Projects = React.lazy(() => import("./pages/Projects/ProjectsContainer"));
const Settings = React.lazy(() => import("./pages/Settings"));
const VirtualMachine = React.lazy(() =>
  import("./pages/VirtualMachine/VirtualMachineContainer")
);
const VirtualMachineItem = React.lazy(() =>
  import("./pages/VirtualMachineItem/VirtualMachineItemContainer")
);
const VirtualMachineNew = React.lazy(() =>
  import("./pages/VirtualMachineNew/VirtualMachineNewContainer")
);

const K8sList = React.lazy(() => import("./pages/K8sList/K8sListContainer"));
const K8sNewContainer = React.lazy(() =>
  import("./pages/K8sNew/K8sNewContainer")
);
const ReactivatePage = React.lazy(() => import("./pages/Reactivate"));
const TicketsPage = React.lazy(() => import("./pages/Tickets"));
const TicketCreatePage = React.lazy(() => import("./pages/Tickets/Create"));
const TicketPage = React.lazy(() => import("./pages/Tickets/Id"));
const WaitingListPage = React.lazy(() =>
  import("./pages/WaitingList/WaitingList")
);
const MaintenancePage = React.lazy(() =>
  import("./pages/Maintenance/Maintenance")
);
const SecretsPage = React.lazy(() => import("./pages/Vault"));

const ReferralPage = React.lazy(() => import("./pages/Referrals"));

let timerId;

const AppRouter = () => {
  const navigate = useNavigate();
  const user = useAuthUser();
  const team = useCurrentTeam();

  const { isSkip: isSkipPaymentMethod } = useSkipPaymentMethod(team?._id);
  const { isSkip: isSkipNeeds } = useSkipNeeds(user?._id);

  const isOwner = someTeamOwnerByUser(user)(team);
  const neNeedNeeds = useNeNeedNeeds();
  const checkNotPaymentVerified = useCheckPaymentVerifiedByTeam(team);
  const billingSettings = useBillingSettingsByTeam(team);
  const paymentMethods = usePaymentMethodsByBillingSettingsId(
    billingSettings?._id
  );

  const needVerify = () => user && user.emailVerified === false;
  const goToPath = (path) => () => (navigate(path), path);
  const goToConfirm = goToPath(CONFIRM_ROUTE);

  const isDeactivated = user && user.status === userStatuses.deactivated;

  useEffect(() => {
    clearTimeout(timerId);
    if (user) {
      if (needVerify()) goToConfirm();
      else if (isOwner) {
        const isRedirected = () =>
          [NEEDS_ROUTE, paymentVerificationRoute].includes(getPathName());
        const neRedirected = complement(isRedirected);
        const redirectOnce = once(navigate);
        const goToNeedsSave = () => redirectOnce(NEEDS_ROUTE);
        const goToPaymentVerificationSave = () =>
          redirectOnce(paymentVerificationRoute);
        const goToBillingSave = () => redirectOnce(billingRoute);

        const checkOrRedirectPaymentVerify = async () => {
          if (isSkipPaymentMethod()) return false;
          const needVerify = await checkNotPaymentVerified();
          if (!needVerify) return false;
          if (
            ![billingRoute, paymentVerificationRoute].includes(getPathName())
          ) {
            if (paymentMethods.some(isAllowPaymentMethod))
              return goToBillingSave();
            return goToPaymentVerificationSave();
          }
        };

        const checkOrRedirectNeeds = async () => {
          if (isSkipNeeds()) return checkOrRedirectPaymentVerify();
          const isSended = await neNeedNeeds();
          if (isSended) return checkOrRedirectPaymentVerify();
          if (neRedirected()) return goToNeedsSave();
          checkOrRedirectPaymentVerify();
        };

        checkOrRedirectPaymentVerify().then(
          () => neRedirected() && checkOrRedirectNeeds()
        );
      }
    }
  }, [user, team]);

  if (isNeedAuth()) {
    if (
      needVerify() &&
      ![CONFIRM_ROUTE, profileRoute].includes(getPathName())
    ) {
      return <Navigate to={CONFIRM_ROUTE} />;
    }

    if (neAuth() && isRootPath()) {
      return <Navigate to={AUTH_ROUTE} />;
    }
  }

  if (isRootPath() && isEmpty(user)) {
    timerId = setTimeout(() => {
      navigate(AUTH_ROUTE);
      showError("Something went wrong! Please try again later.");
    }, 10000);

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <PageLoader />
      </Box>
    );
  }

  if (isDeactivated && !isCurrentPathName(REACTIVATE_ROUTE)) {
    return <Navigate to={REACTIVATE_ROUTE} />;
  }

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={<DashboardLayout />}>
          <Route index element={<Navigate replace to={projectsRoute} />} />
          <Route path={projectsRoute} element={<Projects />} />
          <Route path={projectsNewRoute} element={<CreateProject />} />
          <Route path={projectItemRoute} element={<ProjectItem />} />
          <Route path={`${settingsRoute}/:teamId`} element={<Settings />} />
          <Route path={virtualMachineRoute} element={<VirtualMachine />} />
          <Route
            path={`${virtualMachineRoute}/:id`}
            element={<VirtualMachineItem />}
          />
          <Route path={`${databaseRoute}/:id`} element={<DatabaseItem />} />
          <Route path={`${appRoute}/:id`} element={<AppItem />} />
          <Route path={KUBERNETES_ROUTE} element={<K8sList />} />
          <Route path={KUBERNETES_NEW_ROUTE} element={<K8sNewContainer />} />
          <Route
            path={virtualMachineNewRoute}
            element={<VirtualMachineNew />}
          />
          <Route path={databaseNewRoute} element={<DatabaseNew />} />
          <Route path={appNewRoute} element={<AppNew />} />
          <Route path={databaseRoute} element={<DatabasesList />} />
          <Route path={appRoute} element={<AppsList />} />
          <Route path={billingRoute} element={<Billing />} />
          <Route path={TICKETS_ROUTE}>
            <Route index element={<TicketsPage />} />
            <Route path="create" element={<TicketCreatePage />} />
            <Route path=":ticketId" element={<TicketPage />} />
          </Route>
          <Route path={SECRETS_ROUTE}>
            <Route index element={<SecretsPage />} />
          </Route>
        </Route>
        <Route path="/" element={<BasicLayout />}>
          <Route path={loginRoute} element={<Login />} />
          <Route path={profileRoute} element={<Profile />} />
          <Route path={deactivateRoute} element={<Deactivate />} />
          <Route path={forgotPasswordRoute} element={<ForgotPassword />} />
          <Route
            path={`${forgotPasswordRoute}/:token`}
            element={<ResetPassword />}
          />
          <Route
            path={`${confirmEmailRoute}/:token`}
            element={<ConfirmEmail />}
          />
          <Route path={registrationsRoute} element={<Registrations />} />
          <Route path={CONFIRM_ROUTE} element={<Confirm />} />
          <Route
            path={paymentVerificationRoute}
            element={<PaymentVerification />}
          />
          <Route
            path={paymentVerificationRoute + "/:billingSettingsId"}
            element={<PaymentVerification />}
          />
          <Route path={REACTIVATE_ROUTE} element={<ReactivatePage />} />
          <Route path={NEEDS_ROUTE} element={<Needs />} />
          <Route path={createTeamRoute} element={<CreateTeam />} />
          <Route
            path={createTeamRoute + "/:teamId"}
            element={<CreateTeamEdit />}
          />
          <Route
            path={createTeamRoute + "/:teamId/" + PAYMENT_METHOD_PATH}
            element={<CreateTeamAddPaymentMethod />}
          />
          <Route
            path={createTeamRoute + "/:teamId/" + INVITE_PATH}
            element={<CreateTeamInvite />}
          />
          <Route path={INVITE_ROUTE + "/:inviteId"} element={<InvitePage />} />
          <Route path={REFERRAL_ROUTE} element={<ReferralPage />} />
          <Route path={maintenance} element={<MaintenancePage />} />
          <Route path={waitingList} element={<WaitingListPage />} />
        </Route>
        <Route path={AUTH_ROUTE} element={<AUTH />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRouter;
