import axiosInstance from "../axiosInstance";

const PATH = "/tickets";

const ticketApi = Object.freeze({
  createTicket: ({ subject, content, files }) => axiosInstance.post(PATH, {
    subject,
    content,
    files
  }).then((result) => {
    const id = result.data;
    result.data = {
      id,
      content,
      subject,
      createdAt: Date.now(),
    };
    return result;
  }),

  createNest: ({ ticketId, content, files }) => axiosInstance.post(PATH + "/" + ticketId, {
    content,
    files,
  }).then((result) => {
    result.data = {
      parentId: ticketId,
      content,
      createdAt: Date.now(),
    };
    return result;
  }),

  getTickets      : () => axiosInstance.get(PATH),

  getTicketById   : ticketId => axiosInstance.get(PATH + "/" + ticketId),

  closeTicketById : ticketId => axiosInstance.delete(PATH + "/" + ticketId).then((result) => {
    result.data = ticketId;
    return result;
  }),
});

export default ticketApi;
