// hooks
import useCheckPaymentVerifiedByBillingSettingsId from "../useCheckPaymentVerifiedByBillingSettingsId";
import useBillingSettingsByTeamId                 from "../../billingSettings/useBillingSettingsByTeamId";
import useFetchBillingSettingsByTeamId            from "../../billingSettings/useFetchBillingSettingsByTeamId";

import Null from "../../../helpers/fp/function/Null";

const useCheckPaymentVerifiedByTeam = (team) => {
  const teamId                       = team?._id;
  const billingSettings              = useBillingSettingsByTeamId(teamId);
  const fetchBillingSettingsByTeamId = useFetchBillingSettingsByTeamId();
  const checkPaymentVerified         = useCheckPaymentVerifiedByBillingSettingsId();

  return teamId ? () => {
    if (billingSettings) return checkPaymentVerified(billingSettings._id)

    return fetchBillingSettingsByTeamId(teamId)
      .then(({ payload, error }) => {
        if (error && error.message === "Request failed with status code 404") return true;
        if (payload && payload.data && payload.data._id) return checkPaymentVerified(payload.data._id);
        return true;
      });
  } : Null;
};

export default useCheckPaymentVerifiedByTeam;
