import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { prop } from 'lodash/fp';
import trap from '../../helpers/fp/decorators/trap';
import ENV from '../../constants/ENV';

const url = [ENV.REACT_APP_API_URL, "config"].join("/");
const fetchPublicConfig = trap(() => axios.get(url).then(prop("data")));

const publicConfigAsyncActions = Object.freeze({
  getPublicConfig: createAsyncThunk('get/publicConfig', fetchPublicConfig),
});

export default publicConfigAsyncActions;
