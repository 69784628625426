// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";

const getDatabases             = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/databases`);
const getDatabase              = (dbId) => getTrap(`/databases/${dbId}`);
const deleteDatabase           = ({ dbId }) => axiosInstance.delete(`/databases/${dbId}`);
const createDatabase           = (databases) => axiosInstance.post(`/databases`, databases);

const databaseService = Object.freeze({
  getDatabases,
  getDatabase,
  deleteDatabase,
  createDatabase
});

export default databaseService;
