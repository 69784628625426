import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import useFetchTeamById from "./useFetchTeamById";
import indexed from "../../helpers/fp/array/indexed";
import useTeams from "./useTeams";
import Null from "../../helpers/fp/function/Null";

const indexedById = indexed("_id");

/**
  * @typedef {import("../../types/Team").default} Team
  */

/**
  * @param {string} [teamId]
  * @returns {{
  *    team : Team | null,
  *    isLoading : boolean,
  *    fetchTeam : () => Promise<unknown>
  * }}
  */
const useTeamById = (teamId) => {
  const teams         = useTeams();
  const findTeamById  = teamId ? indexedById(teams) : Null;

  const team          = findTeamById(teamId);
  const draft         = useSelector((state) => state.team.draft);
  const currentTeam   = team || (draft && draft._id === teamId ? draft : null);
  const hasTeam       = Boolean(currentTeam);
  const isLoading     = useRef(!hasTeam);
  const fetchTeamById = useFetchTeamById();

  const fetchTeam = () => {
    if (teamId) {
      isLoading.current = true;
      fetchTeamById(teamId).finally(() => {
        isLoading.current = false;
      });
    }
  }

  useEffect(() => {
    if (!hasTeam) fetchTeam();
  }, [teamId]);

  return ({
    team      : currentTeam,
    isLoading : isLoading.current,
    fetchTeam,
  });
};

export default useTeamById;
